<template>
    <v-app class="bg-signup">
        <v-main>
            <v-container fill-height>
                <v-flex xs12 md12 lg10 xl8 class="mx-auto card-wrapper">
                    <v-card rounded="lg" style="z-index: 100">
                        <div
                          class="d-flex justify-space-betweeen flex-column flex-md-row"
                          style="min-height: 650px"
                        >
                            <!-- Inicia contenido lado izquierdo -->
                            <div class="px-4 py-4 py-my-0 px-sm-8 px-md-16 flex-shrink-1 flex-grow-1 d-flex ">
                                <div class="flex-grow-1 flex-shrink-1" style="max-width: 100%">
                                    <v-form 
                                        ref="form"
                                        style="background-color: #FFFFFF;"
                                        @submit.prevent="enviarFormulario()" 
                                    >
                                        <h5 class="text-uppercase text-h6 font-weight-bold text-center">Registrarse</h5>
                                        <v-divider class="mt-2" />
                                        <v-text-field
                                            v-model="form.correo"
                                            label="Correo electrónico"
                                            class="mt-4"
                                            placeholder="Ingrese el correo"
                                            :error-messages="correoErrors"
                                            :disabled="creacionUsuario.isLoading"
                                            outlined
                                            @change="$v.form.correo.$touch()"
                                            @blur="$v.form.correo.$touch()"
                                        />
                                        <v-text-field
                                            v-model="form.documento"
                                            label="DUI"
                                            placeholder="Documento de identidad"
                                            class="input-with-append"
                                            v-mask="'########-#'"
                                            :error-messages="documentoErrors"
                                            :disabled="creacionUsuario.isLoading"
                                            outlined
                                            @change="$v.form.documento.$touch()"
                                            @blur="$v.form.documento.$touch()"
                                        >
                                            <template v-slot:append-outer>
                                                <v-btn
                                                    color="success" 
                                                    class="white--text mt-2"
                                                    @click.stop="verificarDocumento"
                                                    :loading="verificacionDUI.isLoading"
                                                    :disabled="!captcha_validado || creacionUsuario.isLoading"
                                                >
                                                    Verificar
                                                </v-btn>
                                            </template>
                                        </v-text-field>
                                        <VueSimpleRecaptcha
                                            v-if="isDui(form.documento) && !captcha_validado"
                                            :sitekey="captchaKey"
                                            ref="recaptcha"
                                            @callback="manejarCallbackCaptcha"
                                        />
                                        <div class="mt-4 d-flex flex-wrap flex-md-wrap" style="gap: 16px;">
                                            <v-btn 
                                                class="flex-shrink-1 flex-grow-1" 
                                                color="primary" 
                                                type="submit"
                                                large
                                                :disabled="!verificacionDUI.data"
                                                :loading="creacionUsuario.isLoading"
                                            >
                                                Continuar
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </div>
                            </div>
                            <!-- Finaliza contenido lado izquierdo -->

                            <v-divider vertical class="flex-shrink-0" />
                            <v-divider class="d-md-none" />

                             <!-- Inicia contenido lado derecho -->
                             <div
                                class="signup-right-content px-4 py-4 pt-md-0 flex-grow-1"
                                style="flex-basis: 225px !important"
                            >
                                <div style="max-width: 100%">
                                    <div style="flex-basis: 225px !important">
                                        <div class="d-flex flex-column px-4 py-4" style="gap: 16px;">
    
                                            <v-btn 
                                                class="flex-shrink-1 flex-grow-1 boton-accion" 
                                                color="primary" 
                                                text
                                                block
                                                to="/login-procesos-compra"
                                            >
                                                Iniciar sesión
                                            </v-btn>

                                            <v-btn 
                                                class="flex-shrink-1 flex-grow-1 boton-accion" 
                                                color="primary" 
                                                text
                                                block
                                                to="/login"
                                            >
                                                Volver a COMPRASAL
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <!-- Finaliza contenido lado derecho -->
                        </div>
                    </v-card>
                </v-flex>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { required, helpers } from 'vuelidate/lib/validators';
import VueSimpleRecaptcha from "vue-simple-recaptcha";
import NavButton from '@/components/utils/NavButton.vue';
import { LocalStorage } from '@/utils/LocalStorage.mjs';

const initialFormState = {
    correo: null,
    documento: null,
};

const correo = helpers.regex(
  "email",
  /^[a-zA-Z\d]+[a-zA-Z\d._-]*@([a-zA-Z\d]+(-|.)?){0,3}[a-zA-Z\d]+(\.[a-zA-Z\d]+)?(\.[a-zA-Z\d]{2,})$/
);

const dui = helpers.regex("dui",/^\d{8}-\d$/);


export default {
    components: { NavButton, VueSimpleRecaptcha },
    validations: {
        form: {
            correo: { required, correo },
            documento: { required, dui },
        },
    },
    data: () => ({
        // UI
        verificacionDUI: createLoadable(null),
        form: { ...initialFormState },
        captcha_validado: false,
        token_captcha: null,
        mostrar_mensaje_exito: false,

        // Data
        creacionUsuario: createLoadable(null),
    }),
    computed: {
        captchaKey() {
            return process.env.VUE_APP_CAPTCHA;
        },
        correoErrors() {
            const errors = [];
            if (!this.$v.form.correo.$dirty) return errors;

            !this.$v.form.correo.required && errors.push("El correo es requerido.");
            !this.$v.form.correo.correo && errors.push("El correo debe ser válido.");

            return errors;
        },
        documentoErrors() {
            const errors = [];
            if (!this.$v.form.documento.$dirty) return errors;

            !this.$v.form.documento.required && errors.push("El DUI es requerido.");
            !this.$v.form.documento.dui && errors.push("El DUI debe ser válido.");

            return errors;
        },
    },
    methods: {
        // UI
        limpiarFormulario() {
            this.$v.$reset();
            this.$refs.form.reset();
            this.form = { ...initialFormState };
        },
        enviarFormulario() {
            this.$v.$touch();

            if (this.$v.$invalid) return;

            this.crearUsuario();
        },
        manejarCallbackCaptcha(token) {
            this.token_captcha = token;
            this.captcha_validado = true;
        },
        volver() {
            if (this.to) {
                this.$router.push(this.to);
                return;
            }

            if (window.history.length > 1) {
                this.$router.back();
            } else {
                this.$router.push('/');
            }
        },
        // DATA
        async verificarDocumento() {
            toggleLoadable(this.verificacionDUI);
            const { data } = await this.services.RegistroCiudadanos.verificarDocumento({ documento: this.form.documento });
            setLoadableResponse(this.verificacionDUI, data);
        },
        async crearUsuario() {
            toggleLoadable(this.creacionUsuario);
            const { data } = await this.services.RegistroCiudadanos.registrarUsuario({ ...this.form });
            setLoadableResponse(this.creacionUsuario, data, { showAlertOnSuccess: true, skipOnSuccess: true });

            if (isResponseSuccesful(data)) {
                this.mostrar_mensaje_exito = true;
                this.limpiarFormulario();
                this.$router.push('/login-procesos-compra');
            }
        },
    },
    mounted() {
        if (LocalStorage.token_procesos_libres) {
            this.$router.push('/procesos-publicos');
        }
    },
}
</script>
<style scoped>
  :deep(.v-input.input-with-append) {
    align-items: flex-start;
  }
  
  :deep(.v-input.input-with-append .v-input__append-outer) {
    margin: 0 8px !important;
  }

.bg-signup {
    background-image: url("../../assets/img/fondoLogin.jpeg") !important;
    background-size: cover !important;
    background-position: center top !important;
    background-repeat: no-repeat !important;
    position: relative;
  }

  .bg-signup::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (min-width: 960px) {
    :deep(.signup-right-content) {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-left-radius: 0;
      overflow: hidden !important;
      background-color: #313945 !important;
    }

    :deep(.boton-accion) {
        color: #FFF !important;
    }
}
</style>